<template>
  <div>
    <div ref="map" style="height: 400px; border: 1px solid #ccc;"></div>
    <button @click="addMarker">添加标记点</button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      map: null,
      centerPoint: new BMapGL.Point(116.404, 39.915), // 中心点坐标
      distance: 5000, // 距离中心点的距离（单位：米）
      bearing: 45 // 方位角（0-360度）
    }
  },
  mounted() {
    // 初始化地图
    this.map = new BMapGL.Map(this.$refs.map)

    // 设置中心点和缩放级别
    this.map.centerAndZoom(this.centerPoint, 15)
  },
  methods: {
    // 添加标记点
    addMarker() {
      // 计算标记点的经纬度坐标
      const markerPoint = this.computeDestinationPoint(
        this.centerPoint,
        this.distance,
        this.bearing
      )

      // 清除地图上的所有覆盖物
      this.map.clearOverlays()

      // 创建标记点并添加到地图上
      const marker = new BMapGL.Marker(markerPoint)
      this.map.addOverlay(marker)
    },
    // 根据中心点、距离和方位角计算目标点的经纬度坐标
    computeDestinationPoint(centerPoint, distance, bearing) {
      const EarthRadius = 6371000 // 地球半径（单位：米）

      // 将距离转换为弧度
      const radianDistance = distance / EarthRadius

      // 将方位角转换为弧度
      const radianBearing = (bearing * Math.PI) / 180

      // 计算目标点的纬度
      const lat1 = (centerPoint.lat * Math.PI) / 180
      const lat2 = Math.asin(
        Math.sin(lat1) * Math.cos(radianDistance) +
          Math.cos(lat1) * Math.sin(radianDistance) * Math.cos(radianBearing)
      )

      // 计算目标点的经度
      const lon1 = (centerPoint.lng * Math.PI) / 180
      const lon2 =
        lon1 +
        Math.atan2(
          Math.sin(radianBearing) * Math.sin(radianDistance) * Math.cos(lat1),
          Math.cos(radianDistance) - Math.sin(lat1) * Math.sin(lat2)
        )

      // 将经纬度转换为度
      const targetLat = (lat2 * 180) / Math.PI
      const targetLng = (lon2 * 180) / Math.PI

      return new BMapGL.Point(targetLng, targetLat)
    },
   
  }
}
</script>

<style scoped>
/* Add your styles here */
</style>
